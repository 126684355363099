import { ENV } from 'src/utils/env'
import * as configCatSSR from 'configcat-js-ssr'
import { getServerSession } from 'next-auth/next'
import { AppContext } from 'next/app'
import { parseCookies } from 'nookies'
import { authOptions } from 'src/pages/api/auth/[...nextauth].api'
import logger from 'src/utils/logger'
import { datadogRum } from '@datadog/browser-rum-slim'
import { Session } from 'next-auth'
import { getConfigCatUser } from 'src/utils/config-cat'
import cookie from 'cookie'

const SDK_KEY = ENV.CONFIGCAT_SDK_KEY
const BASE_URL = ENV.CONFIGCAT_BASE_URL

/**
 * The ConfigCat SSR client is used to evaluate flags in server side code
 * !Depends on the ConfigCat Proxy being publicly accessible (work is in progress at ConfigCat to make this available server-side without exposing it to the public)
 */
export const configCatSSRClient = configCatSSR.getClient(
  SDK_KEY,
  configCatSSR.PollingMode.LazyLoad,
  {
    baseUrl: BASE_URL,
    logger: configCatSSR.createConsoleLogger(configCatSSR.LogLevel.Error),
    offline: ENV.NODE_ENV === 'test',
    setupHooks: (hooks) =>
      hooks.on('flagEvaluated', ({ key, value }) =>
        datadogRum.addFeatureFlagEvaluation(key, value)
      ),
  }
)

/**
 * Evaluates all flags via the ConfigCat SSR client
 * @param context - The Next.js AppContext
 * @returns All flag data from ConfigCat
 */
export async function evaluateAllFlagsSSR(context: AppContext) {
  try {
    const user = await getConfigCatUserFromContext(context)
    return await configCatSSRClient.getAllValuesAsync(user)
  } catch (error) {
    logger.error({
      message: 'Failed to evaluate flags via configCatSSRClient',
      error,
    })
    throw error
  }
}

/**
 * Evaluates a single flag via the ConfigCat SSR client
 * @param context - The Next.js AppContext
 * @param key - The flag key
 * @param defaultValue - The default value to return if the flag is not found
 * @returns
 */
export async function evaluateFlagSSR<T extends configCatSSR.SettingValue>(
  context: AppContext,
  key: string,
  defaultValue: T
) {
  try {
    const user = await getConfigCatUserFromContext(context)
    return await configCatSSRClient.getValueAsync(key, defaultValue, user)
  } catch (error) {
    logger.error({
      message: `Failed to evaluate flag: ${key} via configCatSSRClient`,
      error,
    })
    throw error
  }
}

/**
 * Takes the NextAuth session and cookies and returns the ConfigCat user object
 * @param context - The Next.js AppContext
 * @returns - The ConfigCat user object
 */
export async function getConfigCatUserFromContext(
  context: AppContext,
  custom: Record<string, any> = {}
) {
  const { req, res } = context.ctx

  const mergedCookies = getMergedCookies(context.ctx)
  const session = await getServerSession(req as any, res as any, authOptions)

  return getConfigCatUserWithCookies(session, mergedCookies, custom)
}

/**
 * Returns the standard user object to be used in all ConfigCat evaluations
 * @param session - The NextAuth session
 * @param cookies - The cookies from the request
 * @param custom - Any additional custom properties to add to the user object
 * @returns The ConfigCat user object
 */
export function getConfigCatUserWithCookies(
  session: Session | null,
  cookies: Record<string, string>,
  custom: Record<string, any> = {}
) {
  const CompanySlug = cookies?.['bushel-web-company']
  const AppInstallationId = cookies?.['bushel-web-installation-id']

  return getConfigCatUser(CompanySlug, AppInstallationId, session, custom)
}

/**
 * Fetches all ConfigCat flags and returns them as a plain object
 */
export async function fetchAndSerializeConfigCatFlags(context: AppContext) {
  const evaluations = await evaluateAllFlagsSSR(context)
  return Object.fromEntries(
    evaluations.map(({ settingKey, settingValue }) => [settingKey, settingValue])
  )
}

/**
 * Parses the incoming cookies from the request and merges them with any new cookies set by the server. (nookies-parseCookies lacks this ability)
 * If a user changes their company or installationId, the server will respond with a new set-cookie header.
 * Various segments in ConfigCat rely on a stable understanding of those properties to function correctly.
 */
function getMergedCookies(ctx: AppContext['ctx']) {
  const { req, res } = ctx
  const setCookieHeaders = res.getHeader('Set-Cookie')

  let newCookies: Record<string, string> = {}

  if (Array.isArray(setCookieHeaders)) {
    newCookies = cookie.parse(setCookieHeaders.join('; '))
  } else if (typeof setCookieHeaders === 'string') {
    newCookies = cookie.parse(setCookieHeaders)
  }

  return { ...parseCookies({ req }), ...newCookies }
}
