import { queryOptions, useQuery } from '@tanstack/react-query'
import { useFeatureFlag } from 'configcat-react'
import { aggregatorService } from 'src/api'
import { useAuth } from 'src/auth'
import { customPagesQueryKeys } from 'src/data/queries/custom-pages'
import { fourHoursAsMs } from 'src/data/queries/custom-pages/utils'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'

const getCustomPageByIdOptions = (id: string) =>
  queryOptions({
    queryKey: customPagesQueryKeys.getById(id),
    queryFn: () =>
      id ? aggregatorService.getCustomPageById(id) : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    refetchOnWindowFocus: true,
    gcTime: fourHoursAsMs,
    staleTime: fourHoursAsMs,
    refetchInterval: fourHoursAsMs,
    meta: { errorMessage: 'Unable to retrieve that page, please try again.' },
  })

type GetCustomPageByIdOptions = Partial<ReturnType<typeof getCustomPageByIdOptions>>

export default function useGetCustomPageById(id?: string, options: GetCustomPageByIdOptions = {}) {
  const { isStaff } = useAuth()
  const { value: isCustomPagesEnabled } = useFeatureFlag(
    CONFIGCAT_FLAGS.isCustomPagesEnabled,
    false
  )
  return useQuery({
    ...getCustomPageByIdOptions(id),
    enabled: !!id && !isStaff && isCustomPagesEnabled,
    ...options,
  })
}
