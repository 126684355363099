import { SettingValue } from 'configcat-react'
import { createContext, useContext } from 'react'
import type { ConfigCatFlagKey } from 'src/utils/config-cat'

export type ConfigCatFlagsSSR = Partial<Record<ConfigCatFlagKey, SettingValue>>

const ConfigCatSSRContext = createContext<ConfigCatFlagsSSR | null>(null)

/**
 * The SSR flag evaluation hook equivalent of `useFeatureFlag`, used sparingly due to:
 * !No polling for changes to flags
 * !Changes in user state will also not be reflected in these flag evaluations
 *  - Useful for top level components like Sidebar where loading states/re-evaluations would cause problems for consistency
 * @param flagKey - The key of the feature flag to evaluate @see ConfigCatFlagKey
 * @param defaultValue - The default value to return if the flag is not found
 * @returns The evaluated value of the feature flag
 */
export default function useFeatureFlagSSR<TValue extends SettingValue>(
  flagKey: ConfigCatFlagKey,
  defaultValue: TValue
) {
  const context = useContext(ConfigCatSSRContext)
  return (context?.[flagKey] as Widen<TValue>) ?? defaultValue
}

/**
 * Use the entire set of feature flags in the SSR context
 * @returns All current evaluations of feature flags and a getter for per-flag evaluations @see getFlagValue
 */
export function useFeatureFlagsSSR() {
  const context = useContext(ConfigCatSSRContext)

  /**
   * Get the value of a feature flag from the SSR context synchronously. Useful for components
   * that do many evaluations of flags in a single render cycle.
   * @generic TValue - The type of the flag value (inferred from the default value)
   * @param flagKey - The key of the feature flag to evaluate @see ConfigCatFlagKey
   * @param defaultValue - The default value to return if the flag is not found
   * @returns The evaluated value of the feature flag
   */
  const getFlagValue = <TValue extends SettingValue>(
    flagKey: ConfigCatFlagKey,
    defaultValue: TValue
  ) => (context?.[flagKey] as Widen<TValue>) ?? defaultValue

  return { getFlagValue, flags: context }
}

type ConfigCatSSRProviderProps = Readonly<React.PropsWithChildren<{ flags: ConfigCatFlagsSSR }>>

export function ConfigCatSSRProvider({ flags, children }: ConfigCatSSRProviderProps) {
  return <ConfigCatSSRContext.Provider value={flags}>{children}</ConfigCatSSRContext.Provider>
}
