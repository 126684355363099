import { queryOptions, useQuery } from '@tanstack/react-query'
import { useFeatureFlag } from 'configcat-react'
import { aggregatorService } from 'src/api'
import { useAuth } from 'src/auth'
import { fourHoursAsMs } from 'src/data/queries/custom-pages/utils'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'
import { customPagesQueryKeys } from './customPagesQueryKey'

const getStaffCustomPageByIdOptions = (id: string) =>
  queryOptions({
    queryKey: customPagesQueryKeys.getByIdStaff(id),
    queryFn: () =>
      id
        ? aggregatorService.getStaffCustomPageById(id)
        : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    refetchOnWindowFocus: true,
    gcTime: fourHoursAsMs,
    staleTime: fourHoursAsMs,
    refetchInterval: fourHoursAsMs,
    meta: { errorMessage: 'Unable to retrieve that page, please try again.' },
  })

type GetStaffCustomPageByIdOptions = Partial<ReturnType<typeof getStaffCustomPageByIdOptions>>

export default function useGetStaffCustomPageById(
  id?: string,
  options: GetStaffCustomPageByIdOptions = {}
) {
  const { isStaff } = useAuth()
  const { value: isStaffCustomPagesEnabled } = useFeatureFlag(
    CONFIGCAT_FLAGS.isStaffCustomPagesEnabled,
    false
  )
  return useQuery({
    ...getStaffCustomPageByIdOptions(id),
    enabled: !!id && isStaff && isStaffCustomPagesEnabled,
    ...options,
  })
}
